import moment from 'moment';
import React from 'react'
import { CSVLink } from 'react-csv';

function HexGroupOverdue({data}) {
    console.log(data);
  return (
    <div>
         <CSVLink
              className="ml-1"
              data={data}
              filename={`${moment(new Date()).format("DD-MMM-YYYY")}_Hex_Wise.csv`}
            >
              <button className="btn btn-primary">Download Csv</button>
            </CSVLink>
       {data.length > 0 && (
        <table className="table table-sm table-bordered mt-3">
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th scope="col">Name</th>
              <th>Hex</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {data.sort((a, b) => b.amount - a.amount).map((e, i) => {
              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td className="pr-15 pl-55" style={{ fontWeight: "bold" }}>
                    {e.name}
                  </td>
                  <td>{e.generatedHex}</td>
                  <td>{e.amount}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default HexGroupOverdue