import {
    Backdrop,
    Box,
    Button,
    Fade,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function SmartMeterDetailsModal({
    open,
    setOpen,
    setShowClient,
    setBillingDetails,
}) {
    const handleClose = () => setOpen(false);
    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            startDate: "",
            endDate: "",
            commonArea: true,
            onePhaseRate: "",
            twoPhaseRate: "",
            threePhaseRate: "",
        },
    });

    const onSubmit = (data) => {
        console.log("Smart meter Data - ", data);
        setBillingDetails(data);
        setOpen(false);
        setShowClient(true);
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                            <h4 className="mb-4">Smart Meter Billing Details</h4>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row" >
                                <div className="col-6 mb-3">
                                    <TextField
                                        type="date"
                                        sx={{ width: "100%" }}
                                        label="State Date"
                                        {...register("startDate", {
                                            required: "Required",
                                        })}
                                        error={errors.startDate}
                                        helperText={errors.startDate?.message}
                                        focused
                                    />
                                </div>
                                <div className="col-6 mb-3">
                                    <TextField
                                        sx={{ width: "100%" }}
                                        type="date"
                                        label="End Date"
                                        {...register("endDate", {
                                            required: "Required",
                                        })}
                                        error={errors.endDate}
                                        helperText={errors.endDate?.message}
                                        focused
                                    />
                                </div>
                         
                                <div className="col-6 mb-3">
                                    <Controller
                                        control={control}
                                        name="commonArea"
                                        render={({ field }) => (
                                            <FormControl
                                                fullWidth
                                                // sx={{ p: 1 }}
                                            >
                                                <InputLabel id="common-area-label">
                                                    Include Common Area
                                                </InputLabel>
                                                <Select
                                                    labelId="common-area-label"
                                                    id="demo-simple-select-helper"
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    label="Include Common Area"
                                                     sx={{ height: "100%", p: 1 }}
                                                >
                                                    <MenuItem value={true}>
                                                        True
                                                    </MenuItem>
                                                    <MenuItem value={false}>
                                                        False
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                </div>
                                <div className="col-6 mb-3">
                                    <TextField
                                        sx={{ width: "100%" }}
                                        type="number"
                                        label="One Phase Rate"
                                        {...register("onePhaseRate", {
                                            required: "Required",
                                        })}
                                        error={errors.onePhaseRate}
                                        helperText={
                                            errors.onePhaseRate?.message
                                        }
                                    />
                                </div>
                         
                                <div className="col-6 mb-3">
                                    <TextField
                                        sx={{ width: "100%" }}
                                        label="Two Phase Rate"
                                        {...register("twoPhaseRate", {
                                            required: "Required",
                                        })}
                                        error={errors.twoPhaseRate}
                                        helperText={
                                            errors.twoPhaseRate?.message
                                        }
                                    />
                                </div>
                                <div className="col-6 mb-3">
                                    <TextField
                                        label="Three Phase Rate"
                                        {...register("threePhaseRate", {
                                            required: "Required",
                                        })}
                                        error={errors.threePhaseRate}
                                        helperText={
                                            errors.threePhaseRate?.message
                                        }
                                    />
                                </div>
                            </div>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ marginTop: 1 }}
                            >
                                Submit
                            </Button>
                        </form>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
