import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv';
import { serverUrl } from '../ApiUrl';
import { notify, notifyFailed } from '../Common/Notification';
import { cleanNumber } from '../Function/AxiosControl';
import Spinner from '../Function/Spinner';

function ClientGroupOverdue() {
    const [phoneWiseGroup,setPhoneWiseGroup] = useState([])
    const [loading,setLoading] = useState(false)
    const [filterType,setFilterType]= useState(0)
    useEffect(()=>{
      setLoading(true)
      axios.get(`${serverUrl.u}/invoice/status`).then((res)=>{
        if(res.data.success){
          notify(res.data.message)
          getClientWise(res.data.data)
        }else{
          notifyFailed(res.data.message)
          setLoading(false)
        }
      }).catch((err)=>{
        let msg = err?.response?.data?.message?(err?.response?.data?.message):err?.message
        notifyFailed(msg)
        setLoading(false)
      })
    },[])
    const getClientWise = (data) => {
  
      let clientgroup = {};
      for (let dt of data) {
        let phone = cleanNumber(dt.phone);
        if (clientgroup[phone] === undefined) {
          clientgroup[phone] = [];
        }
        clientgroup[phone].push(dt);
      }
     let dataArr= Object.keys(clientgroup).reduce((p,c)=>{
        let obj={name:clientgroup[c][0].name,phone:`'${c}`,amount:0,date:null}
        for(let i=0;i<clientgroup[c].length;i++){
           if(i===0){
            obj.date=clientgroup[c][0].createdAt
           }
           obj.amount+=clientgroup[c][i].overdueAmount
        }
        return [...p,{...obj,amount:obj.amount.toFixed(2)}]
      },[])
      console.log(dataArr,"phone");
      setPhoneWiseGroup(dataArr)
      setLoading(false)
    };
    const sortFunction = (data)=>{
      let d=[]
      if(filterType===2 || filterType===3){
       d = filterType===2?data.sort((a, b) => b.amount - a.amount):data.sort((a, b) => a.amount - b.amount)
      
      }else{
      d = filterType===0 ?  data.sort((a, b) =>new Date(a.date) - new Date(b.date))
        : data.sort((a, b) =>new Date(b.date) - new Date(a.date));
      }
      return d
    }
    // useEffect(()=>{
    //   if(phoneWiseGroup.length===0) return
    //   setLoading(true)
    //   console.log('ca11');
    
    //   let d=[]
    //   if(filterType===2){
    //    d = phoneWiseGroup.sort((a, b) => b.amount - a.amount)
      
    //   }else{
    //   d = filterType===0 ?  phoneWiseGroup.sort((a, b) =>new Date(a.date) - new Date(b.date))
    //     : phoneWiseGroup.sort((a, b) =>new Date(b.date) - new Date(a.date));
    //   }
    //   setPhoneWiseGroup(d)
    //   console.log('ca12');
    //   setLoading(false)
    // },[filterType,phoneWiseGroup])
  return (
    <div> 
          <div className='row align-items-end'>
          <div class="input-group col-4">
          <div className="w-100">
            <label>Type of Sort</label>
            <select
              class="custom-select height-select"
              required
              id="inputGroupSelect01"
              onChange={(e) => {
                setFilterType(Number(e.target.value));
              }}
              name="invoiceDate"
              value={filterType}
              autoComplete="invoiceDate"
            >
              <option value={0}>Date Ascending</option>
              <option value={1}>Date Descending</option>
              <option value={2}>Amount Ascending</option>
              <option value={3}>Amount Descending</option>
              <input type="text" />
            </select>
          </div>
        </div>
        <CSVLink
              className="ml-1 col-2"
              data={phoneWiseGroup}
              filename={`${moment(new Date()).format("DD-MMM-YYYY")}_Client_Wise.csv`}
            >
              <button className="btn btn-primary">Download Csv</button>
            </CSVLink>
          </div>
           
           {loading && <Spinner/>}
       {phoneWiseGroup.length > 0 && (
        <table className="table table-sm table-bordered mt-3">
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th scope="col">Name</th>
              <th>Phone</th>
              <th>Amount</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {sortFunction(phoneWiseGroup).map((e, i) => {
              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td className="pr-15 pl-55" style={{ fontWeight: "bold" }}>
                    {e.name}
                  </td>
                  <td>{e.phone}</td>
                  <td>{e.amount}</td>
                  <td>{moment(e.date).format('llll')}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default ClientGroupOverdue